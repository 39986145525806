import React from 'react'
import { Container, Row } from 'react-grid-system'
import styled from 'styled-components'
import { NavLink } from '../widgets'

interface MenuItem {
  title: string
  to: string
}

interface MainFooterProps {
  /** Whether the content is contained, or not. */
  contained?: boolean

  /** The menu items array. */
  menuItems?: MenuItem[]

  /** The copyright info. */
  copyright?: {
    title: string
    to: string
    name: string
  }
}

const MainFooterEl = styled.footer`
  background-color: #fff;
  height: 3.75rem;
  border-top: 1px solid #e1e5eb;
  padding: 0.5rem 1rem;
  display: flex;
  width: 100%;
  bottom: 0;

  @media (max-width: 768px) {
    display: block;
    height: auto;
  }
`

const MainFooterNav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    border-bottom: 1px solid #e1e5eb;
    display: block;
    padding-bottom: 0.75rem;
    width: 100%;
  }
`

const Copyright = styled.span`
  font-size: 0.8125rem;
  color: #818ea3;
  padding: 0.625rem;

  @media (max-width: 768px) {
    display: block;
    padding: 1rem;
    text-align: center;
    width: 100%;
    height: 52px;
    line-height: 1.5;
  }
`

const MainFooter: React.FC<MainFooterProps> = ({ contained, menuItems, copyright }) => (
  <MainFooterEl>
    <Container fluid={contained}>
      <Row>
        <MainFooterNav>
          {menuItems?.map((item, idx) => (
            <li key={idx}>
              <NavLink
                target={item.to.startsWith('http') ? '_blank' : ''}
                rel={item.to.startsWith('http') ? 'noreferrer' : ''}
                href={item.to}
              >
                {item.title}
              </NavLink>
            </li>
          ))}
        </MainFooterNav>
        <Copyright>
          {copyright?.title}{' '}
          <a href={copyright?.to} target='_blank' rel='noreferrer'>
            {copyright?.name}
          </a>
        </Copyright>
      </Row>
    </Container>
  </MainFooterEl>
)

MainFooter.defaultProps = {
  contained: false,
  copyright: {
    title: 'Copyright © 2015-2023',
    name: 'One Step Projects',
    to: 'https://onestepprojects.org',
  },
  menuItems: [
    {
      title: 'Home',
      to: 'https://onestepprojects.org',
    },
    {
      title: 'Privacy Policy',
      to: 'https://onestepprojects.org/privacy-policy',
    },
    {
      title: 'Terms of Use',
      to: 'https://onestepprojects.org/website-terms-of-use',
    },
    {
      title: 'Contact',
      to: 'https://onestepprojects.org/contact',
    },
  ],
}

export default MainFooter
