import { Auth } from 'aws-amplify'
import { FC, useEffect } from 'react'

/** Redirect to login page. */
const Login: FC = () => {
  useEffect(() => {
    Auth.federatedSignIn()
  })
  return null
}

export default Login
