import React from "react";
import styled from 'styled-components';
import { Container } from "react-grid-system";

const ErrorContainer = styled.div`
  display: flex;
  height: calc(100vh - 3.75rem);
`;

const ErrorContent = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  margin: auto;
  padding: 0 1rem;
  text-align: center;
`;


const NotFound: React.FC = () => (
  <Container fluid>
    <ErrorContainer>
      <ErrorContent>
        <h2>404</h2>
        <h3>Page Not Found</h3>
      </ErrorContent>
    </ErrorContainer>
  </Container>
);

export default NotFound;
