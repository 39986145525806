import React from "react";

// TODO: Awaiting publish package
// import { RewardsWidget } from "@onestepprojects/rewards-widget";

interface RewardsProps {
  person: any;
}

const Rewards: React.FC<RewardsProps> = ({ person }) => {
  return (
    <div className="main-content-container container-fluid px-4">
      <div
        className="page-header row no-gutters pt-4 pb-1 mb-3 border-bottom justify-content-between"
        style={{ position: "relative" }}
      >
        <div className="col-10 col-sm-4 text-center text-sm-left mb-0">
          <span className="text-uppercase page-subtitle">Rewards</span>
          <h3 className="page-title">My Rewards</h3>
        </div>
      </div>
      <div className="col-md-auto text-sm-left mb-0">
        {/* <RewardsWidget
          payId={person.paymentAccount.payID}
          entityId={person.uuid}
        /> */}
      </div>
    </div>
  );
};

export default Rewards;
