import React from "react";
import Errors from "./views/Errors";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(_error: unknown, errorInfo: React.ErrorInfo) {
    this.setState({
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return Errors(this.props);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
