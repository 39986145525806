import React from 'react'
import styled from 'styled-components'

// TODO: The Icon is based on material-icon, is it need to decouple may need to discuss
export const Icon = styled.i.attrs({
  className: 'material-icons',
})`
  font-size: 1.25rem !important;
`

interface SidebarNavItem {
  title: string
  icon: React.ReactNode
  to: string
  admin: boolean
}

const sidebarNavItems = (): SidebarNavItem[] => {
  return [
    {
      title: 'Dashboard',
      icon: <Icon>dashboard</Icon>,
      to: '/organizations',
      admin: false,
    },
    {
      title: 'Health',
      icon: <Icon>medical_services</Icon>,
      to: '/health',
      admin: false,
    },
    {
      title: 'Solutions',
      icon: <Icon>library_books</Icon>,
      to: '/solutions',
      admin: false,
    },
    {
      title: 'Resources',
      icon: <Icon>local_offer</Icon>,
      to: '/resource',
      admin: false,
    },
    {
      title: 'Crowdfunds',
      icon: <Icon>payment</Icon>,
      to: '/',
      admin: false,
    },
    {
      title: 'Accreditations',
      icon: <Icon>verified</Icon>,
      to: '/accreditation',
      admin: false,
    },
    {
      title: 'Accreditation Management',
      icon: <Icon>verified</Icon>,
      to: '/accreditation/admin',
      admin: true,
    },
    {
      title: 'Surveys',
      icon: <Icon>poll</Icon>,
      to: '/survey',
      admin: false,
    },
    {
      title: 'Tickets',
      icon: <Icon>theaters</Icon>,
      to: '/tickets',
      admin: false,
    },
  ]
}

export default sidebarNavItems
