import React from "react";
import styled from 'styled-components';
import { Container } from "react-grid-system";

const ErrorContainer = styled.div`
  display: flex;
  height: calc(100vh - 3.75rem);
`;

const ErrorContent = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  margin: auto;
  padding: 0 1rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #3d5170;
  font-weight: 400;
  line-height: 2.25rem;
`;

const SubTitle = styled.h2`
  font-size: 2rem;
  color: #3d5170;
  font-weight: 400;
  line-height: 2.25rem;
`;

const Errors: React.FC = () => (
  <Container fluid>
    <ErrorContainer>
      <ErrorContent>
        <Title>500</Title>
        <SubTitle>Something went wrong!</SubTitle>
        <p>There was a problem on our end. Please try again later.</p>
      </ErrorContent>
    </ErrorContainer>
  </Container>
);

export default Errors;
