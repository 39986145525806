import { Auth } from 'aws-amplify'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Person } from '../../../..//types/user'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'

export const Button = styled.button`
  cursor: pointer;
  background-color: inherit;
  border-color: #212529;
  color: #212529;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125;
  padding: 0.5rem 1rem;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
`

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`

const DropdownToggle = styled.a`
  display: block;
  font-size: .8rem;
  font-weight: 400;
  padding: .625rem 1rem;

  &:after {
    border-bottom: 0;
    border-left .3em solid transparent;
    border-right: .3em solid transparent;
    border-top: .3em solid;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
  }
`

const DropdownText = styled.span`
  display: inline-block;

  @media (max-width: 768px) {
    display: none;
  }
`

const Collapse = styled.div`
  position: absolute;
  top: 60px;
  right: 10px;
  width: 100px;
  background-color: #fff;
  left: auto;
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
  box-shadow: 0 0.5rem 2rem rgb(0 0 0 / 11%), 0 3px 10px rgb(0 0 0 / 5%), 0 2px 3px rgb(0 0 0 / 6%);
  font-size: 0.8rem;
  padding: 0.25rem 0;

  & .dropdown-item {
    font-size: 0.8rem;
    padding: 0.375rem 0.875rem;
  }

  & .dropdown-divider {
    margin: 0.25rem 0;
  }
`

const DropdownItemLink = styled.a`
  display: block;
  font-size: 0.813rem;
  font-weight: 300;
  padding: 0.375rem 0.875rem;
  clear: both;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #eceeef;
    text-decoration: none;
  }

  & .material-icons {
    top: 2px;
    font-size: inherit;
    position: relative;
  }
`

const DropdownItemLogout = styled(DropdownItemLink)`
  color: #c4183c !important;
`

const DropdownItemDivider = styled.div`
  margin: 0.25rem 0;
  border-top: 1px solid #e9ecef;
  height: 0;
`

const UserActionContainer = styled.div`
  margin-right: 3rem; /* mr-5 */
`

const PersonAvatar = styled(Avatar)`
  margin-right: 0.5rem;
`

const UserActionsDropdown: React.FC<{
  person: Person
  onBackdropClick: () => void
}> = ({ person, onBackdropClick }) => {
  const [container] = useState(() => document.createElement('div'))

  useEffect(() => {
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  })

  return ReactDOM.createPortal(
    <Backdrop onClick={onBackdropClick}>
      <Collapse>
        <DropdownItemLink href={`/organizations/persons/${person.uuid}`}>
          <i className='material-icons'>&#xE7FD;</i> Profile
        </DropdownItemLink>
        <DropdownItemDivider />
        <DropdownItemLogout onClick={() => Auth.signOut()}>
          <i className='material-icons text-danger'>&#xE879;</i> Logout
        </DropdownItemLogout>
      </Collapse>
    </Backdrop>,
    container
  )
}

const UserActions: React.FC = () => {
  const { person, user } = useContext(ApplicationContext)
  const [visible, setVisible] = useState<boolean>(false)
  const toggleVisible = useCallback(() => {
    setVisible(!visible)
  }, [visible])

  if (!user) return <Button onClick={() => Auth.federatedSignIn()}>Log In</Button>

  if (!person) return null

  return (
    <UserActionContainer>
      <DropdownToggle onClick={toggleVisible}>
        <PersonAvatar
          name={person.name}
          src={person.profilePicture.name ? person.profilePicture.url : null}
          round={true}
          alt='User Avatar'
          size='40px'
        />
        <DropdownText>{person.name}</DropdownText>
      </DropdownToggle>
      {visible ? <UserActionsDropdown person={person} onBackdropClick={toggleVisible} /> : null}
    </UserActionContainer>
  )
}

export default UserActions
