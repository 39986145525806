import { createContext } from "react";
import { match } from 'react-router-dom-v5';
import { User, Person } from '../types/user';

interface ApplicationContextData {
  person: Person | undefined | null;
  user: Partial<User> | undefined;
  isAdmin: boolean;
  match: match;
}

export const ApplicationContext = createContext<ApplicationContextData>({
  person: undefined,
  user: undefined,
  match: {
    params: {},
    isExact: false,
    path: '',
    url: '',
  },
  isAdmin: false,
});
