import React, { useContext, useState } from 'react'
import { Container, Visible } from 'react-grid-system'
import styled from 'styled-components'

import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { Navbar } from '../../widgets'

import SidebarNavItems from '../MainSidebar/SidebarNavItems'
import NavbarNav from './NavbarNav/NavbarNav'
import NavbarToggle from './NavbarToggle'

import logo from '../../../images/color-one-step-logo.png'

const MainNavbarEl = styled.div`
  background-color: #fff;
  box-shadow: 0 0.125rem 0.625rem #5a61691f;
  z-index: 1;
  top: 0;
`

const MainNavbarContainer = styled(Container)`
  position: sticky !important;
  padding: 0 !important;
`

interface MainNavbarThebarProps {
  personEnabled: boolean
}

const MainNavbarThebar = styled(Navbar)<MainNavbarThebarProps>`
  margin-right: auto;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }

  ${(props) => {
    if (!props.personEnabled) {
      return `
        align-items: center;
        margin-right: auto;
      `
    }
    return `
      align-items: stretch;
      justify-content: flex-end;
    `
  }}
`

const MainLogo = styled.img.attrs({
  src: logo,
  alt: 'One Step',
})`
  margin-right: 0.25rem;
  display: inline-block;
  vertical-align: top;
  max-width: 135px;
`

const MainNavbar: React.FC = () => {
  const { person, user } = useContext(ApplicationContext)
  const [show, setShow] = useState(false)

  const toggle = () => setShow(!show)

  return (
    <>
      <MainNavbarEl>
        <MainNavbarContainer>
          <MainNavbarThebar personEnabled={!!person}>
            {/* The logo should display in sidebar, but if user isn't logged-in, the sidebar will not render, so draw another logo here */}
            {!user && !person && (
              <a href='/'>
                <MainLogo />
              </a>
            )}
            {/* {person && (
              <a href="/rewards">
                <RewardsWidgetNavBar entityId={person.uuid} />
              </a>
            )} */}
            <NavbarNav />
            {person && <NavbarToggle onClick={toggle} />}
          </MainNavbarThebar>
        </MainNavbarContainer>
      </MainNavbarEl>
      <Visible xs sm>
        <SidebarNavItems show={show} />
      </Visible>
    </>
  )
}

export default MainNavbar
