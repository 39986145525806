import React, { useState } from 'react'
import { Col, Hidden } from 'react-grid-system'
import styled from 'styled-components'

import SidebarMainNavbar from './SidebarMainNavbar'
import SidebarNavItems from './SidebarNavItems'

interface SidebarMainNavbarProps {
  $show: boolean
}

const MainSideBarEl = styled(Col).attrs<SidebarMainNavbarProps>((props) => ({
  component: 'aside',
  width: props.$show ? '300px' : '0px',
}))<SidebarMainNavbarProps>`
  background-color: #fff;
  box-shadow: 0 0.125rem 9.375rem rgb(90 97 105 / 10%), 0 0.25rem 0.5rem rgb(90 97 105 / 12%),
    0 0.9375rem 1.375rem rgb(90 97 105 / 10%), 0 0.4375rem 2.1875rem rgb(165 182 201 / 10%);
  top: 0;
  transition: width 0.2s ease-in-out;
  padding-left: 0 !important;
  padding-right: 0 !important;
  z-index: 2;

  & .nav-item {
    width: 100%;
    overflow: hidden;
  }

  & .nav-item:hover,
  & .nav-item.active {
    box-shadow: inset 0.1875rem 0 #007bff;
    color: #007bff;

    i {
      color: #007bff;
    }
  }
`

const ToggleButton = styled.button<SidebarMainNavbarProps>`
  position: absolute;
  left: ${(props) => (props.$show ? '262px' : '0px')};
  bottom: 0px;
  width: 38px;
  height: 32px;
  background-color: transparent;
  color: #555;
  border: 1px solid transparent;
  z-index: 3; /* higher than sidebar */

  &:hover {
    color: #007bff;
    border: 1px solid #006aff;
  }

  &:active {
    color: #000;
    border: 1px solid #000;
  }
`

const MainSidebar: React.FC = () => {
  const [sidebarShowing, setSidebarShowing] = useState<boolean>(true)

  return (
    <Hidden sm xs>
      <MainSideBarEl $show={sidebarShowing}>
        <SidebarMainNavbar />
        <SidebarNavItems />
      </MainSideBarEl>
      <ToggleButton
        $show={sidebarShowing}
        onClick={() => setSidebarShowing(!sidebarShowing)}
        title={sidebarShowing ? 'Collapse sidebar' : 'Expand sidebar'}
      >
        <i className='material-icons'>{sidebarShowing ? 'menu_open' : 'menu'}</i>
      </ToggleButton>
    </Hidden>
  )
}

export default MainSidebar
