import React from "react";
import { Container, Row, Col } from "react-grid-system";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainFooter from "../components/layout/MainFooter";
import ErrorBoundary from "../ErrorBoundary";

interface LoggedOutLayoutProps {
  children: React.ReactNode;

  /** Whether to display the navbar, or not. */
  noNavbar: boolean,

  /** Whether to display the footer, or not. */
  noFooter: boolean;
}

const LoggedOutLayout: React.FC<LoggedOutLayoutProps> = ({ children, noNavbar, noFooter }) => (
  <Container fluid>
    <Row>
      <Col className="main-content p-0" component="main">
        {!noNavbar && <MainNavbar />}
        <ErrorBoundary>{children}</ErrorBoundary>
        {!noFooter && <MainFooter />}
      </Col>
    </Row>
  </Container>
);

LoggedOutLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default LoggedOutLayout;
