import { CognitoUser } from 'amazon-cognito-identity-js'
import Amplify, { Auth, Hub } from 'aws-amplify'
import 'chart.js'
import { useEffect, useMemo, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom-v5'

import { AuthHelper } from '@onestepprojects/authentication-helper'
import Fund from '@onestepprojects/fund-module'
import Organization from '@onestepprojects/organization-module'

import { ApplicationContext } from './contexts/ApplicationContext'

import amplifyConfig from './data/aws-amplify.config'
import { DefaultLayout } from './layouts'
import LoggedOutLayout from './layouts/LoggedOut'
import routes from './routes'
import Login from './views/Login'
import NotFound from './views/NotFound'

Amplify.configure(amplifyConfig)

const App = () => {
  const history = useHistory()

  const [user, setUser] = useState<CognitoUser | null>(null)
  const [roles, setRoles] = useState<string[]>([])
  const [person, setPerson] = useState(null)
  const [uniqueId, setUniqueId] = useState(Math.random())
  const [loading, setLoading] = useState(true)

  const token = useMemo(() => user?.getSignInUserSession()?.getAccessToken().getJwtToken(), [user])
  const isAdmin = useMemo(() => roles?.includes('admin'), [roles])
  const auth = useMemo(() => ({ token, person, roles }), [token, person, roles])

  useEffect(() => {
    const signIn = async () => {
      try {
        const user = await getUser()
        setUser(user)
      } catch (error) {
        setLoading(false)
      }
    }

    signIn()
  }, [history])

  useEffect(() => {
    if (!user) return

    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          setUser(await getUser())
          break
        case 'signOut':
          setUser(null)
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
        default:
          break
      }
    })
  }, [user])

  useEffect(() => {
    if (!user) return

    const authHelper = new AuthHelper()

    const getPerson = async () => {
      const response = await authHelper.Get(
        process.env.REACT_APP_ORG_MODULE_API_URL + '/persons/requester/token'
      )

      if (response && response.status === 200 && response.data) {
        setPerson(response.data)
      } else {
        history.push('/organizations/registration')
        setUniqueId(Math.random())
      }
    }

    getPerson().then(() => {
      setLoading(false)
    })
  }, [user, history])

  useEffect(() => {
    if (!user) return

    const getAdmin = async () => {
      const authHelper = new AuthHelper()

      const response = await authHelper.Get(`${process.env.REACT_APP_AUTHORIZATION_API_URL}/user`)

      if (response.status === 200) {
        setRoles(response.data.data.roles)
      }
    }

    getAdmin()
  }, [user])

  const displayLoggedIn = () => {
    const authHelper = new AuthHelper()

    return (
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props: any) => {
                  if (typeof route.css === 'function') route.css()

                  return route.admin && !isAdmin ? (
                    <Redirect to='/' />
                  ) : (
                    <ApplicationContext.Provider
                      value={{ match: props.match, person, user, isAdmin }}
                    >
                      <DefaultLayout
                        {...props}
                        person={person}
                        authHelper={authHelper}
                        isAdmin={isAdmin}
                      >
                        <route.component
                          {...props}
                          auth={auth}
                          person={person}
                          authHelper={authHelper}
                          uniqueId={uniqueId}
                          mountPath={route.path}
                        />
                      </DefaultLayout>
                    </ApplicationContext.Provider>
                  )
                }}
              />
            )
          })}
        </Switch>
      </Router>
    )
  }

  const displayNoPerson = () => {
    require('@onestepprojects/organization-module/dist/index.css')
    const authHelper = new AuthHelper()

    return (
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Switch>
          <Route
            path='/organizations'
            component={(props: any) => {
              return (
                <ApplicationContext.Provider value={{ match: props.match, person, user, isAdmin }}>
                  <DefaultLayout {...props} person={person} user={user} isAdmin={isAdmin}>
                    <Organization
                      {...props}
                      auth={auth}
                      person={person}
                      authHelper={authHelper}
                      uniqueId={uniqueId}
                      mountPath={'/organizations'}
                    />
                  </DefaultLayout>
                </ApplicationContext.Provider>
              )
            }}
          />
        </Switch>
      </Router>
    )
  }

  const displayLoggedOut = () => {
    require('@onestepprojects/fund-module/dist/index.css')
    const authHelper = new AuthHelper()

    return (
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Switch>
          <Route path='/login' component={Login} />
          <Route
            path='/'
            component={(props: any) => {
              return (
                <LoggedOutLayout {...props} authHelper={authHelper}>
                  <Fund {...props} authHelper={authHelper} mountPath='/' />
                </LoggedOutLayout>
              )
            }}
          />
          <Route path='*' component={NotFound} />
        </Switch>
      </Router>
    )
  }

  const getUser = async (): Promise<CognitoUser> => {
    return await Auth.currentAuthenticatedUser()
  }

  if (loading) return null

  if (!user && !person) {
    return displayLoggedOut()
  } else if (user && !person) {
    return displayNoPerson()
  } else {
    return displayLoggedIn()
  }
}

export default App
