const amplifyConfig = {
  userPoolId: "" + process.env.REACT_APP_USER_POOL_ID,
  region: "" + (process.env.REACT_APP_USER_POOL_REGION || "us-west-2"),
  identityPoolRegion:
    "" + (process.env.REACT_APP_USER_POOL_IDENTITY_POOL_REGION || "us-west-2"),
  userPoolWebClientId:
    "" + process.env.REACT_APP_USER_POOL_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: "" + process.env.REACT_APP_USER_POOL_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      "" +
      (process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_IN ||
        `${window.location.origin}/`),
    redirectSignOut:
      "" +
      (process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_OUT ||
        `${window.location.origin}/`),
    responseType: "code",
  },
};

export default amplifyConfig;
