import React from "react";
import styled from 'styled-components';
import { Navbar as BaseNavbar } from "../../widgets";
import logo from "../../../images/color-one-step-logo.png";

const Navbar = styled(BaseNavbar)`
  border-bottom: 1px solid #e1e5eb;
`;

const NavbarBrand = styled.a`
  height: 3.75rem;
  overflow: hidden;
  white-space: nowrap;
  background-color: inherit;
  padding: .7rem 0;
  text-align: center;
  width: 100%;
  line-height: 123px;
`;

const Logo = styled.img`
  display: inline-block;
  height: 48px;
  margin-top: -6px; /* Offset fixing */
  vertical-align: top;
`;

const SidebarMainNavbar: React.FC = () => {
  return (
    <Navbar>
      <NavbarBrand href="/">
        <Logo src={logo} alt="One Step" />
      </NavbarBrand>
    </Navbar>
  );
}

export default SidebarMainNavbar;
