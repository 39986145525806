import React, { useContext } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";

import { ApplicationContext } from "../../../contexts/ApplicationContext";
import getSidebarNavItems, { Icon } from "../../../data/sidebar-nav-items";

import { NavLink as BaseNavLink } from "../../widgets";

interface NavItemProps {
  active?: boolean;
}

const NavLink = styled(BaseNavLink)`
  border-bottom: 1px solid #e1e5eb;
  font-weight: 400;
  padding: .9375rem 1.5625rem;
  width: 100%;
  will-change: background-color,box-shadow,color;
  white-space: nowrap;

  & > * {
    display: inline-block;
  }

  & i {
    position: relative;
    top: 4px;
    color: #cacedb;
    margin-right: .375rem;
    min-width: 1.25rem;
    text-align: center;
    transition: color .2s ease;
  }

  & .nav-text {
    white-space: nowrap;
  }
`;

const NavText = styled.span`
  padding-bottom: .625rem;
  padding-top: .625rem;
  font-size: 1rem;
`;

const NavItem = styled.div<NavItemProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  transition: box-shadow .2s ease,color .2s ease,background-color .2s ease;
  white-space: nowrap;
  will-change: background-color,box-shadow,color;
  
  ${({ active }) => active && `
    background-color: #fbfbfb;
    box-shadow: inset 0.1875rem 0 #007bff;
    color: #007bff;

    & ${NavText},
    & ${Icon} {
      color: #007bff;
    }
  `}

  &:hover {
    background-color: #fbfbfb;
    box-shadow: inset 0.1875rem 0 #007bff;
    color: #007bff;

    & ${NavText},
    & ${Icon} {
      color: #007bff;
    }
  }
`;

interface SidebarNavItemsProps {
  show?: boolean;
}

const SidebarNavItems: React.FC<SidebarNavItemsProps> = ({ show = true }) => {
  const { match, isAdmin } = useContext(ApplicationContext);
  const navItems = getSidebarNavItems();
  if (!show) return null;
  return (
    <>
      {
        navItems.filter(item => !(item.admin && !isAdmin)).map((item) => (
          <NavItem key={item.title} active={item.to === match.path}>
            <NavLink href={item.to}>
              { item.icon }
              <NavText>{item.title}</NavText>
            </NavLink>
          </NavItem>
        ))
      }
      <NavItem>
        <NavLink onClick={() => Auth.signOut()}>
          <Icon>logout</Icon>
          <NavText>Logout</NavText>
        </NavLink>
      </NavItem>
    </>
  );
};

export default SidebarNavItems;
