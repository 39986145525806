import styled from 'styled-components'

export const NavLink = styled.a`
  display: block;
  color: #007bff;
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 0.625rem;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);

  &:hover {
    text-decoration: none;
  }

  &:visited {
    color: #007bff;
  }
`

export const Navbar = styled.nav`
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 3.75rem;
`
