import React from 'react'
import styled from 'styled-components'

const NavbarToggleEl = styled.nav`
  display: none;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const NavbarLink = styled.a`
  font-size: 1.5625rem;
  font-weight: 400;
  padding: 0.625rem;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  text-align: center;
  display: block;
  text-decoration: none;
`

interface NavbarToggleProps {
  onClick: () => void
}

const NavbarToggle: React.FC<NavbarToggleProps> = ({ onClick }) => {
  return (
    <NavbarToggleEl onClick={onClick}>
      <NavbarLink>
        <i className='material-icons'>&#xE5D2;</i>
      </NavbarLink>
    </NavbarToggleEl>
  )
}

export default NavbarToggle
